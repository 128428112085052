export const useGlobalsStore = defineStore('globals', {
    state: () => ({
        general: null
    }),

    getters: {
        // Specific subsets
        info: (state) => {
            if (!state.general) {
                return;
            }

            return {
                submenuLists: state.general.submenuLists
            };
        },

        footer: (state) => {
            if (!state.general) {
                return;
            }

            const image = state.general.annualReportImage && state.general.annualReportImage.length ? state.general.annualReportImage[0] : null;

            return {
                annualReport: {
                    title: state.general.annualReportTitle,
                    url: state.general.annualReportUrl,
                    image
                },
                footerLists: state.general.footerLists
            };
        }
    },

    actions: {
        setGeneral(payload) {
            this.general = payload;
        },
    }
});
